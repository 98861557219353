import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/absolute-parts/Templates/SearchPage.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/absolute-parts/Templates/fitmentSearch/fitmentTable.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'

export const compMap = {
  SearchPage,
SearchBox,
FacetPanel,
SearchHeader,
SearchResult,
VehicleWidget,
FitmentTable,
FacetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'SearchBox': TemplateSearchBox,
'FacetPanelContainer': TemplateFacetPanelContainer,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'FacetDialog': TemplateFacetDialog
};