const baseFitmentFields = ['SearchType', 'Make', 'EquipmentModel', 'EngineModel'];
const extraFitmentFields = [];

function InitFunc() {
  if (window.document.querySelector('#cm_results')) {
    window.document.querySelector('body.cms-page-view')?.classList.remove('cms-page-view');
  }
}

function responseHandler({ Facets, ...rest }) {
  return {
    ...rest,
    Facets: Facets.map((f) =>
      f.FieldName === 'engine_model_search' || f.FieldName === 'equipment_model_search'
        ? { ...f, Values: [] }
        : f,
    ),
  };
}

export default {
  platform: 'magento',
  InitFunc,
  responseHandler,
  searchPageUrl: '/search-results',
  SearchRequestDefaults: {
    pageSize: 40,
  },
  autocomplete: {
    order: ['facetValue', 'product'],
  },
  product: {
    fields: {
      id: 'id',
      title: 'name',
    },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'HeaderSearchBox',
      type: 'SearchBox',
      location: '.searchBlock .form-search',
      template: 'SearchBox',
    },
    {
      type: 'FacetPanel',
      name: 'FacetPanel',
      disableCollapse: true,
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: 'body.cms-search-results .sidebar',
      template: 'FacetPanelContainer',
    },
    {
      type: 'SearchHeader',
      name: 'SearchHeader',
      sortSelectClass: 'form-select form-select--small',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      type: 'SearchResult',
      name: 'SearchResult',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
      isAlwaysActive: true,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { selector: '#cm-home-ymm', class: 'container' },
      template: 'fitmentSearch/homeVehicleWidget',
      fields: baseFitmentFields,
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: 'body.catalog-product-view .product-data-items-content',
        class: 'fitment-table__container',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
  ],
};
