import { isTermColor, isTermRanged } from 'Modules/termType.ts';
import { mainConfig } from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import defaultRequestConfig from 'Platforms/magento/requestConfig.js';
import FacetValue from '../facetValue.ts';
import uiConfig from './uiConfig.js';

const {
  autocomplete: autocompleteConfig = {},
  doAutocompleteRedirect,
  facetResponseHandler: customFacetResponseHandler,
  getIgnoreDefaultSearchParamNames,
  getLocalPreselection: customLocalPreselectionGetter,
  getSearchRequestDefaults: customGetSearchRequestDefaults,
  isAnalyticsEnabled = true,
  maxCountByField = {},
  requestTimeout: timeout = 10000,
  responseHandler: customResponseHandler,
  SearchRequestDefaults,
  storeId,
  Widgets,
} = mainConfig;

const defaults = defaultRequestConfig || {
  facetResponseHandler: null,
  getAutocompleteRequestDefaults: null,
  getCustomerAsync: null,
  getLocalPreselection: null,
  getSearchRequestDefaults: null,
  isReferrerInUrl: false,
  responseHandler: null,
};

const autocompleteDefaultOrder = ['text', 'product', 'facetValue'];

const autocompleteDefaultExtra = defaults.getAutocompleteRequestDefaults
  ? defaults.getAutocompleteRequestDefaults()
  : {};

if (autocompleteConfig.requestDefaults) {
  autocompleteConfig.requestDefaults.extra = {
    ...autocompleteDefaultExtra,
    ...autocompleteConfig.requestDefaults.extra,
  };
} else {
  autocompleteConfig.requestDefaults = {
    extra: autocompleteDefaultExtra,
  };
}

const autocomplete = {
  fields: [],
  order: autocompleteDefaultOrder,
  facetValueOrder: [],
  ...autocompleteConfig,
};

const jsScriptDomain = window.document.currentScript.src;
const isSearchableJsScriptDomain = jsScriptDomain.includes('.myconvermax.com');
const isDevBuild = jsScriptDomain.includes('localhost:3000');

const domainOrigin = new URL(jsScriptDomain).origin;
const extractedStoreId = domainOrigin.replace('https://', '').replace('.myconvermax.com', '');

const serverUrl = isSearchableJsScriptDomain
  ? domainOrigin
  : isDevBuild
    ? `https://${storeId}.myconvermax.com`
    : `https://client.convermax.com/${storeId}`;

const backupServerUrl = isSearchableJsScriptDomain
  ? `https://backup.convermax.com/${extractedStoreId}`
  : `https://backup.convermax.com/${storeId}`;

const localPreselection = getLocalPreselection();

const requestDefaults = {
  ...SearchRequestDefaults,
  ...getSearchRequestDefaults?.(uiConfig.pageType),
  extra: {
    ...SearchRequestDefaults?.extra,
    ...getSearchRequestDefaults?.(uiConfig.pageType)?.extra,
  },
};

const {
  pageSize: defaultPageSize,
  selection: defaultSelection = [],
  sort: defaultSort = 'relevance',
} = requestDefaults || {};

const hasSearchResults = Widgets.some((w) => w.visible && w.hasSearchResults);

const needInitRequest = Widgets.some((w) => w.needInitRequest);
const needInitItemRequest = Widgets.some(
  (w) =>
    w.visible &&
    ((w.type === 'RelatedItems' && w.getSelection) || w.type === 'ProductData' || w.type === 'RelatedColors'),
);

// stored via localStorage or Cookies or in config
const clientSideStoredFields = defaultSelection.map((f) => f.field);

const getCustomerAsync = defaults.getCustomerAsync || null;

const requestConfig = {
  autocomplete,
  autocompleteDefaultOrder,
  backupServerUrl,
  clientSideStoredFields,
  defaultPageSize,
  defaultSelection,
  defaultSort,
  doAutocompleteRedirect,
  facetResponseHandler,
  getCustomerAsync,
  getIgnoreDefaultSearchParamNames,
  hasSearchResults,
  isAnalyticsEnabled,
  isReferrerInUrl: defaults.isReferrerInUrl,
  localPreselection,
  maxCountByField,
  needInitItemRequest,
  needInitRequest,
  requestDefaults,
  responseHandler,
  serverUrl,
  storeId,
  timeout,
};

window.Convermax.templates.config.requestConfig = requestConfig;
export default requestConfig;

function getLocalPreselection() {
  const getPreselection = customLocalPreselectionGetter || defaults.getLocalPreselection;
  const preselection =
    getPreselection && getPreselection(uiConfig.pageType, defaults.getLocalPreselection?.defaults);

  return (
    preselection?.map((v) => ({
      ...v,
      term:
        v.noEscape || isTermRanged(v.term) || isTermColor(v.term)
          ? v.term
          : FacetValue.escapeTermValue(v.term),
    })) ?? []
  );
}

function responseHandler(response) {
  let processedResponse = response;

  if (defaults.responseHandler) {
    processedResponse = defaults.responseHandler(processedResponse);
  }

  if (customResponseHandler) {
    processedResponse = customResponseHandler(processedResponse);
  }

  return processedResponse;
}

function facetResponseHandler(response) {
  let processedResponse = response;

  if (defaults.facetResponseHandler) {
    processedResponse = defaults.facetResponseHandler(processedResponse);
  }

  if (customFacetResponseHandler) {
    processedResponse = customFacetResponseHandler(processedResponse);
  }

  return processedResponse;
}

function getSearchRequestDefaults(pageType) {
  let searchRequestDefaults;

  if (defaults.getSearchRequestDefaults) {
    searchRequestDefaults = {
      ...searchRequestDefaults,
      ...defaults.getSearchRequestDefaults(pageType),
      extra: {
        ...searchRequestDefaults?.extra,
        ...defaults.getSearchRequestDefaults(pageType)?.extra,
      },
    };
  }

  if (customGetSearchRequestDefaults) {
    searchRequestDefaults = {
      ...searchRequestDefaults,
      ...customGetSearchRequestDefaults(pageType),
      extra: {
        ...searchRequestDefaults?.extra,
        ...customGetSearchRequestDefaults(pageType)?.extra,
      },
    };
  }

  return searchRequestDefaults;
}
