import 'Stores/absolute-parts/search.css';

import _forIn from 'lodash-es/forIn.js';
import { initializeAddons } from 'Addons';
import { fitmentSearchEnabled } from '!!extract-features!Stores/absolute-parts/config.js';

import getFitmentSearchAPI from 'Addons/fitmentSearch/fitmentSearchAPI.ts';
import history, { uriChangeHandled } from 'Core/history.js';
import store from 'Core/store.ts';
import { clearAutocompleteQuery } from 'Core/actions/autocomplete.ts';
import { dialogOpened } from 'Core/actions/dialog.ts';
import {
  sendInitFitmentSearchRequest,
  sendVerifyFitmentRequest,
  setFitmentTableResponse,
} from 'Core/actions/fitmentSearch/index.js';
import { setProductData } from 'Core/actions/product.ts';
import { replaceInHistory } from 'Core/actions/redirect.ts';
import {
  discardQuery,
  filterQueryResetRequested,
  replaceRequest,
  resetRequest,
} from 'Core/actions/request.js';
import { autocompleteRequestQuerySelector } from 'Core/selectors/autocomplete.ts';
import { searchRequestQuerySelector, searchRequestFilterQuerySelector } from 'Core/selectors/search.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig';
import productConfig from 'Models/uiConfig/productConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { getRequestFromUri, getUriFromRequest } from 'Modules/converter/index.js';
import {
  item as getItemData,
  fitments as getFitments,
  track,
  loadGarageFromAccount,
} from 'Modules/serverApi/index.js';
import { addProductsHistory, isLoggedInStateChanged } from 'Utils/userStorage.js';

export function setupPage() {
  setBrowserGlobals();
  checkDev();
}

function setBrowserGlobals() {
  const cmGlobal = window.Convermax || (window.Convermax = {});

  _forIn(
    {
      version: CONVERMAX_VERSION,
      store: cmGlobal.store || {},
      trackProductView: () => {
        /* back compatibility */
      },
      trackAddToCart: (productId) => {
        track('AddToCart', { ProductId: productId });
      },
      getSearchRequest: () => store.getState().search.request,
      getSearchResponse: () => store.getState().search.response,
      addProductsHistory,
      createSearch: (request) => getUriFromRequest(request).search,
      createSearchUrl: (request) => getUriFromRequest(request).href,
      clearCurrentQuery: () => {
        const state = store.getState();

        if (autocompleteRequestQuerySelector(state)) {
          store.dispatch(clearAutocompleteQuery());
        }

        if (searchRequestQuerySelector(state)) {
          store.dispatch(discardQuery());
        }

        if (searchRequestFilterQuerySelector(state)) {
          store.dispatch(filterQueryResetRequested());
        }
      },
      clearCurrentRequest: (doNotDiscardVehicle) => {
        store.dispatch(
          resetRequest({ discardUserPreselection: !doNotDiscardVehicle, allowSearchSameAsUri: true }),
        );
      },
      getProductAsync: (id) => getItemData(id),
      openDialog: (dialogName) => {
        store.dispatch(dialogOpened(dialogName, { withCurrentVehicle: true }));
      },
    },
    (value, key) => (cmGlobal[key] = value),
  );

  if (fitmentSearchEnabled) {
    const fitmentSearchAPI = getFitmentSearchAPI(store);
    _forIn({ ...fitmentSearchAPI }, (value, key) => (cmGlobal[key] = value));

    if (uiConfig.platform === 'bigcommerce') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      cmGlobal.addVehicleToOrder = (baseUrl, checkoutId, customerId) => {
        const vehicle = fitmentSearchAPI.getVehicleString();
        if (!checkoutId || !baseUrl || !vehicle) {
          return;
        }

        const vehicleNotes = `Vehicle: ${vehicle}.`;

        const options = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerMessage: vehicleNotes,
          }),
        };

        fetch(`${baseUrl}/api/storefront/checkouts/${checkoutId}`, options);
      };
    }
  }
}

function checkDev() {
  if (location.hostname !== 'localhost') {
    // eslint-disable-next-line no-console
    console.log(__('devVersionConsoleWarn'));
  }
}

export function runWhenReady(renderPage) {
  if (uiConfig.deferInit) {
    document.addEventListener('DOMContentLoaded', () => CONVERMAX_INITIALIZE(renderPage));
  } else {
    CONVERMAX_INITIALIZE(renderPage);
  }
}

// initialization

function CONVERMAX_INITIALIZE(renderPage) {
  initializeAddons(store);

  renderPage();

  if (requestConfig.needInitRequest) {
    setUriRequest(window.location, true);
  }
  subscribeToHistory();

  makeInitRequests();

  uiConfig.afterInit();

  window.Convermax.initialized = true;
}

// base init

function subscribeToHistory() {
  history.listen(({ location, action }) => {
    if (action === 'REPLACE') {
      return;
    }

    const requestNeeded = location.state !== uriChangeHandled && requestConfig.hasSearchResults;
    if (requestNeeded) {
      setUriRequest(location);
    } else {
      store.dispatch(replaceInHistory(location, null));
    }
  });
}

function setUriRequest(location, onInit = false) {
  store.dispatch(replaceRequest({ ...getRequestFromUri(location), fromUri: true, onInit }));
}

function makeInitRequests() {
  if (fitmentSearchConfig.needInitFitmentSearchRequest) {
    store.dispatch(sendInitFitmentSearchRequest());
  }

  if (requestConfig.getCustomerAsync) {
    syncCustomerGarage();
  }

  const { localItemId } = productConfig;
  if (localItemId) {
    track('ProductView', { ProductId: localItemId });

    if (requestConfig.needInitItemRequest) {
      getItemData(localItemId).then((item) => store.dispatch(setProductData(item)));
    }

    if (fitmentSearchConfig.needInitFitmentTableRequest) {
      getFitments(localItemId).then((response) => store.dispatch(setFitmentTableResponse(response)));
    }

    if (fitmentSearchConfig.needInitVerifyFitmentRequest) {
      store.dispatch(sendVerifyFitmentRequest({ onInit: true }));
    }
  }
}

async function syncCustomerGarage() {
  try {
    const customer = await requestConfig.getCustomerAsync();
    const isLoggedIn = !!customer;

    if (isLoggedInStateChanged(isLoggedIn) && customer?.id) {
      const vehicleData = await getCustomerGarage(customer.id);

      if (vehicleData?.Garage) {
        getFitmentSearchAPI(store).addVehicleList(vehicleData.Garage);
      }
    }
  } catch (e) {
    console.error("Failed to sync customer's garage", e);
  }
}

function getCustomerGarage(customerId) {
  if (customerId) {
    return loadGarageFromAccount(customerId);
  }
}
