export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'btn btn-secondary',
  PRODUCT_REPEATER_CLASS:
    'products product-list-masonry-change-product list items product-items row row-col-lg-custom-5',

  SEARCH_BOX_PLACEHOLDER:
    'Search by Engine Model, Equipment Model, Part Numbers, Part Number Crosses, and More',
  SELECT_YOUR_VEHICLE: 'Make | Model',

  SHOW_MORE: 'Show more',
  SHOW_LESS: 'Show less',
};
